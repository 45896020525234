import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
// import About from "../views/About.vue";
import Issue from "@/views/Issue";
import Story from "@/views/Story";
import IssueCommunity from "@/views/IssueCommunity";
import IssueNews from "@/views/IssueNews";
import PastEditions from "@/views/PastEditions";
import meetTheTeam from "@/views/meetTheTeam";
import ShopWindow from "@/views/ShopWindow";
import Category from "@/views/Category";
import CookiesPage from "@/views/CookiesPage";
import Sitemap from "@/views/Sitemap";
import AccessibilityPage from "@/views/AccessibilityPage";
import TermsConditions from "@/views/TermsConditions";
import LegalPage from "@/views/LegalPage";
import PrivacyPage from "@/views/PrivacyPage";
import Development from "@/views/development/Development.vue";
import ContactPage from "@/views/ContactPage.vue";
import Regions from "@/views/Regions.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/homepage",
        redirect: "/",
    },
    {
        path: "/issue/:issueSlug",
        name: "Issue",
        component: Issue,
    },
    {
        path: "/:issueSlug-regional-news",
        name: "IssueCommunity",
        component: IssueCommunity,
    },
    {
        path: "/:issueSlug-regional-new",
        name: "IssueCommunityAlt",
        component: IssueCommunity,
    },
    {
        path: "/:issueSlug-news",
        name: "IssueNews",
        component: IssueNews,
    },
    {
        path: "/:issueSlug-new",
        name: "IssueNewsAlt",
        component: IssueNews,
    },
    {
        path: "/:issueSlug-shop-window",
        name: "ShopWindow",
        component: ShopWindow,
    },
    {
        path: "/story/:storySlug",
        name: "Story",
        component: Story,
    },
    {
        path: "/past-editions",
        name: "PastEditions",
        component: PastEditions,
    },
    {
        path: "/meet-the-team",
        name: "MeetTheTeam",
        component: meetTheTeam,
    },
    {
        path: "/cookies",
        name: "cookies",
        component: CookiesPage,
    },
    {
        path: "/contact",
        name: "contact",
        component: ContactPage,
    },
    {
        path: "/accessibility",
        name: "accessibility",
        component: AccessibilityPage,
    },
    {
        path: "/terms-conditions",
        name: "Terms&Conditions",
        component: TermsConditions,
    },
    {
        path: "/legal",
        name: "legal",
        component: LegalPage,
    },
    {
        path: "/privacy",
        name: "privacy",
        component: PrivacyPage,
    },
    {
        path: "/sitemap",
        name: "sitemap",
        component: Sitemap,
    },
    // {
    //     path: "/about",
    //     name: "About",
    //     component: About,
    //     meta: {title: "About"},
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    // },
    {
        path: "/our-values/:categorySlug",
        name: "OurValuesCategory",
        component: Category,
    },
    {
        path: "/our-values",
        name: "OurValues",
        component: Category,
    },
    {
        path: "/region/:regionSlug",
        name: "Regions",
        component: Regions,
    },
    {
        path: "/search",
        name: "Search",
        component: () => import("@/views/Search.vue"),
    },
];

const router = new VueRouter({
    mode: "history",
    routes,
    /** If link is not a scrollTo link */
    scrollBehavior(to) {
        // set scroll offset depending on desktop or mobile
        const scrollOffset = window.innerWidth > 768 ? 200 : 400;
        if (to.hash) {
            setTimeout(() => {
                let elementId = to.hash.replace(/#/, "")
                elementId = decodeURIComponent(elementId)
                const element = document.getElementById(elementId)
                if (element) {
                    const elementPosition = element.getBoundingClientRect().top + window.pageYOffset
                    window.scrollTo({
                        top: elementPosition - scrollOffset,
                        behavior: 'smooth'
                    })
                }
            }, 1500);
        }
        /** If not scrollTo link is passed */
        return {x: 0, y: 0};
    },
});

export default router;
