<template>
  <article v-if="prismicDoc" class="pageTheme pageTheme--withbg">
    <Slices v-if="prismicDocs" :prismicDoc="prismicDoc" :prismicDocs="prismicDocs" :categoriesList="categories" :selectedCategory="selectedCategory" />
    <!-- Spinner while loading -->
    <Spinner v-if="prismicDocs.length === 0" />
  </article>
</template>

<script>
import Slices from "@/components/slices/category-page/Slices";

/** Spinner */
import Spinner from "@/components/slices/shared/Spinner";

export default {
  name: "our-values",
  components: {
    Slices,
    Spinner,
  },
  data() {
    return {
      prismicDocs: [],
      prismicDoc: null,
      categories: ["All"],
      selectedCategory: "All",
    };
  },
  methods: {
    getContent(categorySlug) {
      this.$prismic.client
        .query(
          this.$prismic.Predicates.any("document.type", [
            "story",
              "news_item"
          ]),
          {
            pageSize: 1000,
          }
        )
        .then((document) => {

          /** If category slug is passed , we use it , or otherwise its all categories */
          this.selectedCategory = categorySlug ? categorySlug : 'all';
          this.selectedCategory = this.selectedCategory.charAt(0).toUpperCase() + this.selectedCategory.slice(1)
          if (this.selectedCategory === "Take-a-break") {
            this.selectedCategory = "Take a break";
          }

          /** Build categories and show documents, which are filtered by category*/
          var i;
          for (i = 0; i < document.results.length; i++) {
            let doc = document.results[i];

            if (doc.data.category) {

              /** Add doc category to category list, if category does not exist in the list  */
              if (!this.categories.includes(doc.data.category)) {
                this.categories.push(doc.data.category);
              }

              /** 'All' means user is on category page without a filter */
              if (this.selectedCategory == "All") {
                this.prismicDocs.push(doc);

                /** If not 'All' filter selected, we filter by passed category */
              } else if (this.selectedCategory.toLowerCase() == doc.data.category.toLowerCase()) {
                this.prismicDocs.push(doc);
              }
            }
          }
          /** Sort our categories names */
          this.categories.sort();
        });
      this.trackPageView('our-values - ' + this.selectedCategory)
    },
  },
  created() {
    this.getContent(this.$route.params.categorySlug);
  },
  async mounted() {
    this.prismicDoc = await this.$prismic.client.getByUID('page', 'our-values',
      this.$route.params.issueSlug
    );
    this.fetchSpotlightNavStatus(this.prismicDoc);
  },
  beforeRouteUpdate(to, from, next) {
    /** Reset existing variables, lists, array */
    this.prismicDocs = [];
    this.selectedCategory = "";
    this.categories = ["All"];
    //console.log("Here beforeRouteUpdate: " + to.params.categorySlug);
    this.getContent(to.params.categorySlug);
    next();
  },
};
</script>

<style scoped></style>
