<template>
  <div>
    <div class="desktop-nav__top d-none d-md-block pad35 d-flex align-content-center">
      <b-container>
        <b-row class="h-100 d-flex align-items-center">
          <b-col md="4">
            <span v-if="$route.fullPath === '/'" class="desktop-nav__title">
              {{ $store.state.currentIssueTitle }}
            </span>
          </b-col>

          <b-col class="d-flex align-items-center" md="4">
            <p class="desktop-nav__logo w-100">
              <b-link to="/">
                SPOTLIGHT
              </b-link>
            </p>
          </b-col>

          <b-col class="d-flex align-items-center" md="4">
            <p class="desktop-nav__strapline text-nowrap">The magazine for retail AND property</p>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="desktop-nav__bottom d-none d-md-block pad35">
      <b-container>
        <b-row class="desktop-nav__menu">
          <b-col class="">
            <ul class="menu" v-if="$store.state && $store.state.menus">
              <li class="animate__animated" v-for="(menu, index) in $store.state.menus.mainMenu.data.nav"
                  :key="menu.primary.label[0].text + '-' + index">
                <b-link v-if="menu.primary.link.type === 'homepage'" to="/">{{ menu.primary.label[0].text }}
                </b-link>
                <b-link v-else-if="isEmailLink(menu.primary.link)" :href="menu.primary.link.url"
                        :title="menu.primary.label[0].text">
                  {{ menu.primary.label[0].text }}
                </b-link>
                <b-link v-else-if="menu.primary.link.uid" :to="getPrismicLink(menu.primary.link)"
                        :title="menu.primary.label[0].text">
                  {{ menu.primary.label[0].text }}
                </b-link>
                <b-link v-else-if="menu.primary.link.url && menu.primary.link.url.startsWith('/')"
                        :to="getPrismicLink(menu.primary.link)" :title="menu.primary.label[0].text">
                  {{ menu.primary.label[0].text }}
                </b-link>
                <b-link v-else :href="menu.primary.link.url" :title="menu.primary.label[0].text">
                  {{ menu.primary.label[0].text }}
                </b-link>
              </li>
              <li class="animate__animated searchbox">
                <search-form/>
              </li>
            </ul>
          </b-col>
        </b-row>

      </b-container>
    </div>
  </div>
</template>

<script>
import SearchForm from "@/components/SearchForm.vue";

export default {
  name: "MainNavigationDesktop",
  components: {SearchForm},
  methods: {
    isEmailLink(link) {
      if (link.link_type === 'Web' && link.url.includes('mailto:')) {
        return true
      }
      return false
    },
  }
}
</script>

<style lang="scss">
.desktop-nav {

  &__top {
    height: 100px;
    display: flex;
    color: white;
    background-color: #1D7880;

    p {
      margin-bottom: 0;
    }
  }

  &__bottom {
    background-color: #F9B000;
    color: white;
    height: 50px;

    &--orange {
      background-color: #F9B000;
      color: white;
    }

    &--white {
      background-color: #fff;
      color: black;
    }
  }

  &__title {
    font-size: 1.375em;
    font-weight: 700;
    color: #fff;
  }

  &__logo {
    margin-top: 20px;
    padding-left: 4vw;

    a {
      font-family: "DINCondensed";
      color: white;
      font-size: 50px;
    }
  }

  &__strapline {
    font-size: 1.125rem;
    color: #fff;
    text-transform: uppercase;
  }

  &__menu {
    padding: 0 0;


    @include respond-above(md) {
      padding: 0 50px;
    }

    @include respond-above(lg) {
      padding: 0 146px;
    }

    .menu {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    ul, li {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    li {
      height: 100%;
      position: relative;
      display: block;

      a {
        height: 100%;
        position: relative;
        display: block;

        &:hover, &.router-link-exact-active {
          &:after {
            position: absolute;
            content: '';
            width: 100%;
            top: 33px;
            left: 0;
            height: 9px;
            background-color: #51ACB8;
          }
        }
      }
    }

    .search-form {
      display: flex;
      align-items: end;

      .search__clear {
        display: block;
        height: 20px;
        width: 20px;
        position: absolute;
        right: 14px;
        top: 7px;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      input {
        background-color: transparent;
        transition: all 0.3s ease-in-out;
        border: 0;
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="29.154" height="29.154" viewBox="0 0 29.154 29.154"><defs><clipPath id="clip-path"><rect id="Rectangle_11" data-name="Rectangle 11" width="29.154" height="29.154"/></clipPath></defs><g id="Group_1" data-name="Group 1" clip-path="url(%23clip-path)"><path id="Path_57" data-name="Path 57" d="M29.154,27.051l-7.589-7.59a12.1,12.1,0,1,0-2.1,2.1l7.589,7.59Zm-17.093-5.9a9.086,9.086,0,1,1,9.086-9.086,9.1,9.1,0,0,1-9.086,9.086" transform="translate(0 0)"/></g></svg>');
        font-family: ArialBold;
        padding-inline: 0;
        padding-left: 60px;
        background: {
          size: 30px;
        };
        font-size: 1.375rem;
        width: 180px;
        padding-right: 30px;
        margin-top: 2px;
        height: 46px;
        // truncate text
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:focus {
          width: 300px;
          border: 1px solid black;
        }

        &::placeholder {
          color: black;
        }
      }

      button {
        display: none;
      }
    }
  }

}

.has-scrolled {
  .desktop-nav {
    &__bottom {
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 1030;
      animation: slideIn 0.5s ease-in-out forwards; /* Trigger the animation */
      opacity: 1; /* Make it visible */
      pointer-events: auto; /* Enable interaction */
    }
  }
}

@keyframes slideIn {
  from {
    top: -100%; /* Start above the viewport */
    opacity: 0; /* Fully transparent */
  }
  to {
    top: 0; /* End in the viewport */
    opacity: 1; /* Fully visible */
  }
}
</style>
